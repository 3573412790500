<template>
  <div class="page">
    <v-nav :styleBool="styleBool"></v-nav>
    <div class="img1Box align-center">
      <img src="../assets/banner2.png" alt="" class="banner" />
      <div class="textBox">
        <p class="p1">入驻商屋</p>
        <p class="p2">开启私域增长之路，发掘市场无限商机</p>
      </div>
    </div>

    <div class="box2">
      <div class="title">
        <p class="p1">如何开始使用</p>
        <p class="p2">只需简单几步，即可开启您的私域运营之旅</p>
      </div>
      <ul class="align-center">
        <li>
          <img src="../assets/png1.png" alt="" class="img1" />
          <p class="p3">01 社群申请</p>
          <p class="p4">联系商屋，提交社群入驻申请</p>
        </li>
        <li>
          <img src="../assets/png2.png" alt="" class="img2" />
          <p class="p3">02 平台认证</p>
          <p class="p4">收到申请，商屋平台第一时间进行认证</p>
        </li>
        <li>
          <img src="../assets/png3.png" alt="" class="img3" />
          <p class="p3">03 社群入驻</p>
          <p class="p4">审核通过，私域开通</p>
        </li>
        <li>
          <img src="../assets/png4.png" alt="" class="img4" />
          <p class="p3">04 完善资料</p>
          <p class="p4">社群在管理后台完善资料</p>
        </li>
        <li>
          <img src="../assets/png5.png" alt="" class="img5" />
          <p class="p3">05 邀请企业</p>
          <p class="p4">邀请社群内企业入驻</p>
        </li>
        <li>
          <img src="../assets/png6.png" alt="" class="img6" />
          <p class="p3">06 企业入驻</p>
          <p class="p4">企业完成入驻，开始使用</p>
        </li>
      </ul>
    </div>

    <div class="box3 align-center">
      <div class="conL flex">
        <el-input v-model="name" placeholder="您的姓名"></el-input>
        <el-form
          :model="numberValidateForm"
          status-icon
          :rules="rules"
          ref="numberValidateForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label-width="0px" prop="phone">
            <el-input
              type="string"
              v-model.number="numberValidateForm.phone"
              placeholder="您的电话"
              autocomplete="off"
              :rules="[
                { required: true, trigger: 'blur', message: '请输入手机号' },
                {
                  pattern: /^1(2|3|4|5|6|7|8|9)\d{9}$/,
                  message: '请输入合法手机号',
                  trigger: 'blur',
                },
              ]"
              oninput="if(value.length > 11) value=value.slice(0, 11)"
            ></el-input>
          </el-form-item>
        </el-form>
        <div class="elbox flex">
          <el-input v-model="orgName" placeholder="您的社群名称"></el-input>
          <el-input v-model="email" placeholder="您的邮箱"></el-input>
        </div>

        <el-input
          type="textarea"
          v-model="content"
          placeholder="您想了解的内容"
        ></el-input>
        <el-button type="primary" @click="submitForm('numberValidateForm')"
          >提交</el-button
        >
      </div>
      <div class="conR">
        <div class="title">联系我们</div>
        <div class="item">
          <img src="../assets/png7.png" alt="" class="pngstyle" />
          <p style="white-space: nowrap;">陕西多姿时代科技有限公司</p>
        </div>
        <div class="item">
          <img src="../assets/png8.png" alt="" class="pngstyle2" />
          <p>400-025-5335</p>
        </div>
        <div class="item">
          <img src="../assets/png9.png" alt="" class="pngstyle" />
          <p>business@saanw.com</p>
        </div>
        <div class="item lastitem">
          <img src="../assets/png10.png" alt="" class="pngstyle" />
          <p>万科金域国际总部A栋2103室--西安运营中心</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import nav from "@/components/nav.vue";
import { connect } from "@/api/connect.js";
export default {
  name: "Contact",
  components: {
    "v-nav": nav,
  },
  data() {
    return {
      styleBool: false,
      numberValidateForm: {
        phone: "",
      },

      name: "",
      orgName: "",
      email: "",
      content: "",
      rules: {
        phone: [
          { required: true, trigger: "blur", message: "请输入手机号" },
          {
            pattern: /^((0\d{2,3}-\d{7,8})|(1[3584]\d{9}))$/,
            message: "请输入合法手机号",
            trigger: "blur",
          },
        ],
      },
      bool: false,
    };
  },
  created() {},
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let obj = {
            content: this.content,
            email: this.email,
            name: this.name,
            orgName: this.orgName,
            phone: this.numberValidateForm.phone,
            source: 1,
          };
          connect(obj).then((res) => {
            this.$message({
              message: "提交成功",
              type: "success",
            });
            this.bool = true;
          });
          this.content = "";
          this.email = "";
          this.name = "";
          this.orgName = "";
          // this.numberValidateForm.phone=''
        } else {
          console.log("error submit!!");
          return false;
        }
      });

      this.numberValidateForm.phone = "";
    },
    handleScroll() {
      let top = Math.floor(
        document.documentElement.scrollTop || document.body.scrollTop
      );
      if (top > 0) {
        this.styleBool = true;
      } else {
        this.styleBool = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .page{
    background-color: #f5f4f5;
  }
.elbox {
  margin-bottom: 32px;
  width: 100%;
  justify-content: space-between;
  .el-input {
    margin-bottom: 0 !important;
  }
}
.img1Box {
  background-color: #f5f4f5;
  padding: 1.33rem 3.6rem 0.57rem 4.5rem;

  .banner {
    width: 5.07rem;
    height: 4.82rem;
  }

  .textBox {
    margin-left: 1.94rem;

    .p1 {
      font-size: 60px;
      font-weight: bold;
      margin-bottom: 0.16rem;
      text-align: right;
      white-space: nowrap;
    }

    .p2 {
      font-size: 0.24rem;
      white-space: nowrap;
      display: -moz-inline-box;
    }

    .navbtnBox {
      .el-button {
        padding: 0.13rem 0.34rem;
      }
    }
  }
}

.box2 {
  background-color: #fff;
  padding: 1.12rem 3.6rem 0 3.6rem;

  .title {
    margin-bottom: 0.88rem;
    text-align: center;

    .p1 {
      color: rgba(0, 0, 0, 1);
      font-size: 44px;
      margin-bottom: 0.15rem;
      font-weight: bold;
    }

    .p2 {
      color: rgba(0, 0, 0, 1);
      font-size: 16px;
    }
  }

  ul {
    flex-wrap: wrap;

    li {
      width: 19%;
      margin-right: 0.9rem;
      margin-bottom: 1.18rem;

      .img1 {
        width: 64px;
        height: 66px;
        margin-bottom: 0.42rem;
      }
      .img2 {
        width: 61px;
        height: 71px;
        margin-bottom: 0.42rem;
      }
      .img3 {
        width: 66px;
        height: 66px;
        margin-bottom: 0.42rem;
      }
      .img4 {
        width: 61px;
        height: 66px;
        margin-bottom: 0.42rem;
      }
      .img5 {
        width: 64px;
        height: 66px;
        margin-bottom: 0.42rem;
      }
      .img6 {
        width: 75px;
        height: 66px;
        margin-bottom: 0.42rem;
      }

      .p3 {
        color: rgba(0, 0, 0, 1);
        font-size: 22px;
        margin-bottom: 0.16rem;
        font-weight: bold;
      }

      .p4 {
        font-size: 16px;
      }
    }

    li:nth-child(4) {
      margin-right: 0;
    }
  }
}

.box3 {
  padding: 1.2rem 3.6rem;
  background-color: rgba(245, 244, 245, 1);

  .conL {
    width: 10rem;
    min-width: 7rem;
    padding: 0.73rem 0.38rem;
    background-color: #fff;
    flex-wrap: wrap;
    justify-content: space-between;
    box-shadow: 0px 0px 50px 0px rgba(22, 24, 27, 0.09);

    .el-button {
      padding: 0.13rem 0.52rem;
    }

    .el-input {
      width: 3.3rem;
      height: 0.42rem;
      margin-bottom: 0.32rem;
      .el-input__inner {
        height: 100%;
      }
    }

    .el-form {
      .el-input {
        margin-bottom: 0;
      }
    }

    .el-textarea {
      width: 100%;
      margin-bottom: 0.4rem;
    }

    ::v-deep .el-textarea__inner {
      //el_input中的隐藏属性
      resize: none; //主要是这个样式
      height: 1.08rem;
      width: 100%;
      margin-right: 0 !important;
    }
    .el-button:hover {
      background-color: #5c459e;
    }
  }

  .conR {
    margin-left: 1.09rem;

    .title {
      color: rgba(0, 0, 0, 1);
      font-size: 36px;
      margin-bottom: 0.58rem;
      font-weight: bold;
    }

    .item {
      margin-bottom: 0.55rem;
      display: flex;
      align-items: center;
      p {
        font-size: 16px;
      }
      .pngstyle {
        width: 32px;
        height: 32px;
        margin-right: 0.26rem;
      }
      .pngstyle2 {
        width: 32px;
        height: 32px;
        margin-right: 0.26rem;
      }
    }

    .lastitem {
      margin-bottom: 0;
    }
  }
}
</style>
